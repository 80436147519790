import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useQuery } from "react-query";
import { getXInternalId } from "@/api/X";
import { getPDLCompanyIdBySlug } from "@/api/PDL";

type LookupType = "x" | "pdl";

interface LookupConfig {
  label: string;
  placeholder: string;
  queryFn: (input: string) => Promise<any>;
}

const LOOKUP_CONFIGS: Record<LookupType, LookupConfig> = {
  x: {
    label: "X Profile",
    placeholder: "Enter X username",
    queryFn: getXInternalId,
  },
  pdl: {
    label: "PDL Company",
    placeholder: "Enter company slug",
    queryFn: getPDLCompanyIdBySlug,
  },
};

export function InternalIdLookup() {
  const [input, setInput] = useState("");
  const [lookupType, setLookupType] = useState<LookupType>("x");

  const {
    data, isLoading, error, refetch,
  } = useQuery(
    [lookupType, input],
    () => LOOKUP_CONFIGS[lookupType].queryFn(input),
    {
      enabled: false,
      retry: 1,
    },
  );

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;
    refetch();
  };

  const handleTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newType: LookupType,
  ) => {
    if (newType !== null) {
      setLookupType(newType);
      setInput("");
    }
  };

  const formatResults = () => {
    if (!input) return null;
    return (
      <Box sx={{ p: 2, bgcolor: "grey.100", borderRadius: 1 }}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", p: 3 }}>
      <ToggleButtonGroup
        value={lookupType}
        exclusive
        onChange={handleTypeChange}
        sx={{ mb: 2 }}
      >
        <ToggleButton value="x">X Profile</ToggleButton>
        <ToggleButton value="pdl">PDL Company</ToggleButton>
      </ToggleButtonGroup>

      <form onSubmit={handleSearch}>
        <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
          <TextField
            fullWidth
            label={LOOKUP_CONFIGS[lookupType].placeholder}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            disabled={isLoading}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading || !input.trim()}
          >
            {isLoading ? <CircularProgress size={24} /> : "Search"}
          </Button>
        </Box>
      </form>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Failed to fetch
          {' '}
          {LOOKUP_CONFIGS[lookupType].label}
        </Alert>
      )}

      {data && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Results:</Typography>
          {formatResults()}
        </Box>
      )}
    </Box>
  );
}

export default InternalIdLookup;
