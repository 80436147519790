import React, { useState, useMemo } from "react";
import {
  SortingState,
  ColumnFiltersState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  mapFilters, mapSorting, getNextPageParam,
} from "@/components/InfiniteTable";
import { useInfiniteQuery } from "react-query";
import {
  useQueryParam, withDefault, JsonParam,
} from "use-query-params";
import { getAffinitySignalModelPipeline, getSignalModelPipelineExplainability } from "@/api/Reporting";
import { Box } from "@mui/material";
import MantisTable from "./MantisTable";

export default function MantisTab({
  tab,
  visible,
}: {
  tab: string,
  visible: boolean,
}) {
  const TABLE_DEFAULTS = {
    pipeline: {
      filters: [
        {
          id: "status",
          value: ["Outreach", "Connected", "Access"],
        },
      ],
      visibility: {
        passReason: false,
      },
      sort: [
        { id: "status", desc: false },
        { id: "predictedMoic", desc: true },
      ],
    },
    portfolio: {
      filters: [
        {
          id: "status",
          value: ["Won"],
        },
      ],
      visibility: {
        status: false,
        predictedMoic: false,
        features: false,
        coverage: false,
        lastContacted: false,
        passReason: false,
      },
      sort: [],
    },
    passed: {
      filters: [
        {
          id: "status",
          value: ["Lost"],
        },
      ],
      visibility: {
        status: false,
        features: false,
        amount: false,
        dealType: false,
        lastDealType: false,
        predictedSectors: false,
        location: false,
        lastContacted: false,
        dealDate: false,
        dealSize: false,
        dealPremoney: false,
      },
      sort: [],
    },
  };

  const DefaultFilterQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS[tab]?.filters || [],
  ), [tab]);
  const DefaultVisibilityQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS[tab]?.visibility || {},
  ), [tab]);
  const DefaultSortingQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS[tab]?.sort || [],
  ), [tab]);

  const [sorting, setSorting] = useQueryParam<SortingState>(
    "sorting",
    DefaultSortingQueryParam,
  );
  const [columnFilters, setColumnFilters] = useQueryParam<ColumnFiltersState>(
    "columnFilters",
    DefaultFilterQueryParam,
  );
  const [globalFilter, setGlobalFilter] = useState<string | null>("");
  const [columnVisibility, setColumnVisibility] = useQueryParam<VisibilityState>(
    "columnVisibility",
    DefaultVisibilityQueryParam,
  );

  const pagedQueryKey = useMemo(() => (
    ["SignalModelPipeline", sorting, columnFilters, globalFilter, tab]
  ), [sorting, columnFilters, globalFilter, tab]);
  const chipsPagedQueryKey = useMemo(() => (
    ["SignalModelPipelineChips", sorting, columnFilters, globalFilter, tab]
  ), [sorting, columnFilters, globalFilter, tab]);
  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: pagedQueryKey,
    queryFn: ({ pageParam = null }) => getAffinitySignalModelPipeline(
      50,
      pageParam,
      mapSorting(sorting),
      mapFilters(columnFilters, globalFilter),
    ),
    getNextPageParam,
  });

  const {
    data: chipsData,
  } = useInfiniteQuery({
    queryKey: chipsPagedQueryKey,
    queryFn: ({ pageParam = null }) => getSignalModelPipelineExplainability(
      50,
      pageParam,
      mapSorting(sorting),
      mapFilters(columnFilters, globalFilter),
    ),
    getNextPageParam,
    refetchOnWindowFocus: false,
  });

  const finalData = useMemo(() => {
    if (!data) {
      return null;
    }

    if (!chipsData) {
      return data;
    }

    // flatten all chips data
    const chips = chipsData.pages.reduce((acc, page) => [...acc, ...page.data], []);

    // combine data and chips data
    return {
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        data: page.data.map((row) => ({
          ...row,
          signalFeaturesByDeal: chips
            .find((chip) => (chip.id === row.id))
            ?.signalFeaturesByDeal || [{ features: [] }],
        })),
      })),
    };
  }, [data, chipsData]);

  return (
    <Box
      sx={{
        display: visible ? "block" : "none",
      }}
    >
      <MantisTable
        data={finalData}
        isLoading={isLoading}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        sorting={sorting}
        columnFilters={columnFilters}
        columnVisibility={columnVisibility}
        globalFilter={globalFilter}
        onSortingChange={setSorting}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onColumnVisibilityChange={setColumnVisibility}
      />
    </Box>
  );
}
