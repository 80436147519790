import axios from "axios";
import { paths } from "@/api/index";

type getXInternalId = paths["/api/x/username/{username}/internal-id"]["get"];
export type getXInternalIdResponses = getXInternalId["responses"];
export type getXInternalIdResult =
  getXInternalIdResponses["200"]["content"]["application/json"];

export const getXInternalId = async (
  username: string
): Promise<getXInternalIdResult> => {
  const response = await axios.get<getXInternalIdResult>(
    `/api/x/username/${username}/internal-id`
  );
  return response.data;
};
