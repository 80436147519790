import dayjs from "dayjs";
import React from "react";
import { Box, Card } from "@mui/material";
import { useQuery } from "react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Progress from "@/ui/atoms/Progress";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { getProcessTasks, getProcess } from "@/api/Process";
import Page from "@/ui/molecules/Page";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import TableView from "../ProcessManagement/TableView";

export function NewlyAddedOpportunities() {
  const sinceDate = dayjs().subtract(7, "days").format("YYYY-MM-DD");
  const defaultDateAdded = withDefault(StringParam, sinceDate);
  const defaultQualStatus = withDefault(StringParam, "QUALIFIED");
  const [qualifiedStatus, setQualifiedStatus] = useQueryParam(
    "qualifiedStatus",
    defaultQualStatus
  );
  const [dateAddedFilter, setdateAddedFilter] = useQueryParam(
    "dateAdded",
    defaultDateAdded
  );

  const qualKey =
    "tasks.fieldValues.491faac9-a8cc-4437-9744-0099b52dcd12.choiceId";
  const qualFilter = {
    QUALIFIED: "1e3978b2-0450-47fc-b672-003ae9798e99",
    UNQUALIFIED: "f1b3b3b4-1b3b-4b3b-8b3b-3b3b3b3b3b3b",
  };

  let sortStatement = [
    {
      id: "tasks.fieldValues.65e17c3f-681c-4331-b010-5e65d39212e4.choiceId",
      desc: true,
    },
    {
      id: "tasks.fieldValues.aea69848-6f72-4745-8d1c-b4b53200c317.choiceId",
      desc: true,
    },
    {
      id: "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number",
      desc: true,
    },
  ];

  if (qualifiedStatus === "UNQUALIFIED") {
    sortStatement = [
      {
        id: "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number",
        desc: true,
      },
    ];
  }

  const apiSortStatement = sortStatement.map(
    ({ id, desc }) => `${desc ? "-" : ""}${id}`
  );
  const hardcodedFilters = {
    AND: {
      eq: {
        [qualKey]: [qualFilter[qualifiedStatus]],
      },
      // date of intro
      gte: {
        "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number":
          dayjs(dateAddedFilter).valueOf(),
      },
    },
    OR: {},
  };

  const {
    data: processData = {
      name: "",
      fields: [],
      fieldFilters: [],
      organizationFields: [],
      settings: [],
      lifecycleStages: [],
    },
    isLoading: isProcessLoading,
  } = useQuery(["process", "deals"], async () => getProcess("deals"));

  const { data: tasks = { data: [] }, isLoading } = useQuery(
    ["NewAddlyOpps", dateAddedFilter, qualifiedStatus],
    () =>
      getProcessTasks(["deals"], 500, null, apiSortStatement, hardcodedFilters)
  );

  const columnVisOrder = {
    QUALIFIED: {
      vis: {
        "tasks.fieldValues.44fcec37-381d-bfae-6c4f-e4f530fb376e.value": true,
        "tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.company": true,
        "tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId": true,
        "tasks.fieldValues.491faac9-a8cc-4437-9744-0099b52dcd12.choiceId":
          false,
        "tasks.fieldValues.7bee9f88-6567-47c6-80ef-37c65fc5750f.person": false,
        "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number": true,
        "tasks.fieldValues.68689b23-83c9-4a85-88ee-d7d63211c52b.choiceId":
          false,
        "tasks.fieldValues.f5ca1c59-e6d5-47d0-a79f-55c8cd77efe7.choiceId": true,
        "tasks.fieldValues.080d4bc1-a907-43bd-8837-a3104ff48944.number": true,
        "tasks.fieldValues.9254fc7c-8a22-4f26-877c-c6d49518844c.number": false,
        "tasks.fieldValues.b92d82b2-089c-405e-9628-fe32a479efe9.number": false,
        "tasks.fieldValues.65e17c3f-681c-4331-b010-5e65d39212e4.choiceId": true,
        "tasks.fieldValues.aea69848-6f72-4745-8d1c-b4b53200c317.choiceId": true,
        "tasks.fieldValues.24f4430a-25a5-4d87-beda-5c3c3467ed31.choiceId":
          false,
        "tasks.fieldValues.68db6a5a-ebbd-4ccb-9062-3332a981d2cb.choiceId":
          false,
        "tasks.fieldValues.5ab7cc9e-fe49-4488-b033-e71103fae725.choiceId":
          false,
        "tasks.fieldValues.83ea8f7a-d8de-4379-8f91-d0aa9cd32bef.number": false,
        "tasks.fieldValues.f603685c-b31f-45a9-a5d2-4a07781cdc65.user": false,
        "tasks.fieldValues.8d110765-aece-4e18-969c-baf16d65e9b0.user": false,
        "tasks.fieldValues.1ca1d67a-a31b-43ff-bf3d-8cbc868e6103.user": false,
        "tasks.fieldValues.383eee11-f60f-42d8-8fd3-a194b1be8032.choiceId":
          false,
        "tasks.fieldValues.33145d84-f8f5-4e2b-9c32-1bd38c1bdc22.choiceId": true,
        "tasks.fieldValues.7470a76e-4221-41bf-a67f-e80e006a1322.choiceId":
          false,
        "tasks.fieldValues.59dfaa30-41a6-44ce-b69a-0c01481570a5.choiceId": true,
        "tasks.fieldValues.42df74e8-2a01-4860-8777-18e167f69f62.value": false,
        "tasks.fieldValues.1ed6ea0b-1018-4dcb-8e13-6c16677509f3.choiceId":
          false,
        "tasks.fieldValues.876ee602-842d-4327-9b9e-a88d99bc281f.person": true,
        "tasks.fieldValues.9be28de3-df84-4ad9-add9-c1aad63f7e07.company": false,
        "tasks.fieldValues.e2e0c787-44e4-4189-9116-7a5e670ae2f5.user": true,
        "tasks.fieldValues.0330308f-5c24-41a4-8395-ac1f7c9348b7.value": false,
        "tasks.fieldValues.7eb294a2-9369-423a-8591-04cc6d00d828.choiceId":
          false,
        "tasks.fieldValues.a564d55d-d1f3-4b5a-bf3e-f019d5c38e6c.choiceId":
          false,
        "tasks.fieldValues.e8a4340d-dde7-4771-9bdb-e8adf4cb2949.value": false,
        "tasks.fieldValues.bf70b5b6-b3c8-44f8-a1e6-02fcac632b2f.value": false,
        "tasks.fieldValues.3be015d0-781b-47eb-b63b-9e82ccf4194e.choiceId":
          false,
        "tasks.fieldValues.712ff8ce-0b7c-4538-afab-d6ff4be6f200.choiceId":
          false,
        "tasks.fieldValues.70a87daa-02d5-47f3-8d79-db740d968f33.person": false,
        "tasks.fieldValues.368ac8c1-54b3-45f6-a3fd-d271a7fc9c06.choiceId":
          false,
        "tasks.fieldValues.4508572e-d652-4cc8-90cb-f738e33024ba.value": false,
        "tasks.fieldValues.4a711093-4334-4d4b-bd39-ac3c89b51b16.number": false,
        "tasks.fieldValues.58c43428-c39a-4c63-991f-d693eae32767.value": false,
        "tasks.fieldValues.702608e4-3dc1-4610-bab8-bf52f3461d59.number": false,
        "tasks.fieldValues.68c306dc-d711-4232-be6b-e9d7770b36ee.value": false,
        "tasks.fieldValues.83bde1b6-086a-4073-b3c6-52f81a6b1caa.number": false,
        "tasks.fieldValues.3284219b-6cbf-43ce-8c50-a2dd63f3b035.choiceId":
          false,
        "tasks.fieldValues.9454d58e-6b60-43d4-9461-a58e3b13bf74.number": false,
        "tasks.fieldValues.b938388a-d281-46c5-ac19-9f949f67abb8.choiceId": true,
        "tasks.fieldValues.eab2c0d1-8a47-4a60-877d-b85a0ef9f252.choiceId":
          false,
        "tasks.fieldValues.3fd9c5d2-fcf8-4bab-9c58-31cb0a8be995.user": true,
        "tasks.fieldValues.e0ae21e1-1535-4d0d-a2da-03423e2de836.number": false,
        dateAdded: false,
        "tasks.fieldValues.01dc5b3b-dfb8-4850-957f-8282e69188a3.note": false,
        "tasks.fieldValues.5d90d3c1-a195-4cc9-a17d-09f93cc8c7dc.note": false,
        "tasks.fieldValues.5d90d3c1-a195-4cc9-a17d-09f93cc8c7dc.note": false,
        "tasks.fieldValues.70db637b-4bcf-4d23-a7e3-d5534a5ac2d9.value": false,
        "tasks.fieldValues.068df767-3f63-40d5-8c02-d1b1ee443acc.choiceId":
          false,
        "tasks.fieldValues.175aebcc-f91c-47f7-8568-2b72998c57f8.date": false,
        "tasks.fieldValues.c56c8c3b-99df-443b-9954-f47f916647d0.user": false,
        "tasks.fieldValues.45a9390e-acd0-40da-9e3c-1609ddde5145.person": false,
        "tasks.fieldValues.79a2dd12-40b5-4419-ae4d-0a35c0f225c3.person": false,
        "tasks.fieldValues.175aebcc-f91c-47f7-8568-2b72998c57f8.number": false,
        "tasks.fieldValues.4803b5cb-43da-4829-aaf0-c887347b35bd.value": false,
        "tasks.fieldValues.6a164534-5cd6-462e-8ce6-8ea5e0084c2d.value": false,
        "tasks.fieldValues.fdfbd334-c0ae-4592-8933-f99d82453c7c.choiceId":
          false,
        "tasks.fieldValues.86fef223-2013-4da7-85d4-4fd68b98bde5.choiceId":
          false,
      },
      order: [
        "tasks.fieldValues.44fcec37-381d-bfae-6c4f-e4f530fb376e.value",
        "tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.company",
        "tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId",
        "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number",
        "tasks.fieldValues.f5ca1c59-e6d5-47d0-a79f-55c8cd77efe7.choiceId",
        "tasks.fieldValues.080d4bc1-a907-43bd-8837-a3104ff48944.number",
        "tasks.fieldValues.65e17c3f-681c-4331-b010-5e65d39212e4.choiceId",
        "tasks.fieldValues.aea69848-6f72-4745-8d1c-b4b53200c317.choiceId",
        "tasks.fieldValues.33145d84-f8f5-4e2b-9c32-1bd38c1bdc22.choiceId",
        "tasks.fieldValues.59dfaa30-41a6-44ce-b69a-0c01481570a5.choiceId",
        "tasks.fieldValues.876ee602-842d-4327-9b9e-a88d99bc281f.person",
        "tasks.fieldValues.e2e0c787-44e4-4189-9116-7a5e670ae2f5.user",
        "tasks.fieldValues.b938388a-d281-46c5-ac19-9f949f67abb8.choiceId",
        "tasks.fieldValues.3fd9c5d2-fcf8-4bab-9c58-31cb0a8be995.user",
      ],
    },
    UNQUALIFIED: {
      vis: {
        "tasks.fieldValues.44fcec37-381d-bfae-6c4f-e4f530fb376e.value": true,
        "tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.company": true,
        "tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId": true,
        "tasks.fieldValues.491faac9-a8cc-4437-9744-0099b52dcd12.choiceId":
          false,
        "tasks.fieldValues.7bee9f88-6567-47c6-80ef-37c65fc5750f.person": false,
        "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number": true,
        "tasks.fieldValues.68689b23-83c9-4a85-88ee-d7d63211c52b.choiceId":
          false,
        "tasks.fieldValues.f5ca1c59-e6d5-47d0-a79f-55c8cd77efe7.choiceId":
          false,
        "tasks.fieldValues.080d4bc1-a907-43bd-8837-a3104ff48944.number": false,
        "tasks.fieldValues.9254fc7c-8a22-4f26-877c-c6d49518844c.number": false,
        "tasks.fieldValues.0330308f-5c24-41a4-8395-ac1f7c9348b7.value": true,
        "tasks.fieldValues.b92d82b2-089c-405e-9628-fe32a479efe9.number": false,
        "tasks.fieldValues.65e17c3f-681c-4331-b010-5e65d39212e4.choiceId":
          false,
        "tasks.fieldValues.aea69848-6f72-4745-8d1c-b4b53200c317.choiceId":
          false,
        "tasks.fieldValues.24f4430a-25a5-4d87-beda-5c3c3467ed31.choiceId":
          false,
        "tasks.fieldValues.68db6a5a-ebbd-4ccb-9062-3332a981d2cb.choiceId":
          false,
        "tasks.fieldValues.5ab7cc9e-fe49-4488-b033-e71103fae725.choiceId":
          false,
        "tasks.fieldValues.83ea8f7a-d8de-4379-8f91-d0aa9cd32bef.number": false,
        "tasks.fieldValues.f603685c-b31f-45a9-a5d2-4a07781cdc65.user": false,
        "tasks.fieldValues.8d110765-aece-4e18-969c-baf16d65e9b0.user": false,
        "tasks.fieldValues.1ca1d67a-a31b-43ff-bf3d-8cbc868e6103.user": false,
        "tasks.fieldValues.383eee11-f60f-42d8-8fd3-a194b1be8032.choiceId":
          false,
        "tasks.fieldValues.33145d84-f8f5-4e2b-9c32-1bd38c1bdc22.choiceId": true,
        "tasks.fieldValues.7470a76e-4221-41bf-a67f-e80e006a1322.choiceId":
          false,
        "tasks.fieldValues.59dfaa30-41a6-44ce-b69a-0c01481570a5.choiceId": true,
        "tasks.fieldValues.42df74e8-2a01-4860-8777-18e167f69f62.value": false,
        "tasks.fieldValues.1ed6ea0b-1018-4dcb-8e13-6c16677509f3.choiceId":
          false,
        "tasks.fieldValues.876ee602-842d-4327-9b9e-a88d99bc281f.person": false,
        "tasks.fieldValues.9be28de3-df84-4ad9-add9-c1aad63f7e07.company": false,
        "tasks.fieldValues.e2e0c787-44e4-4189-9116-7a5e670ae2f5.user": true,
        "tasks.fieldValues.7eb294a2-9369-423a-8591-04cc6d00d828.choiceId":
          false,
        "tasks.fieldValues.a564d55d-d1f3-4b5a-bf3e-f019d5c38e6c.choiceId":
          false,
        "tasks.fieldValues.e8a4340d-dde7-4771-9bdb-e8adf4cb2949.value": false,
        "tasks.fieldValues.bf70b5b6-b3c8-44f8-a1e6-02fcac632b2f.value": true,
        "tasks.fieldValues.3be015d0-781b-47eb-b63b-9e82ccf4194e.choiceId":
          false,
        "tasks.fieldValues.712ff8ce-0b7c-4538-afab-d6ff4be6f200.choiceId":
          false,
        "tasks.fieldValues.70a87daa-02d5-47f3-8d79-db740d968f33.person": false,
        "tasks.fieldValues.368ac8c1-54b3-45f6-a3fd-d271a7fc9c06.choiceId":
          false,
        "tasks.fieldValues.4508572e-d652-4cc8-90cb-f738e33024ba.value": false,
        "tasks.fieldValues.4a711093-4334-4d4b-bd39-ac3c89b51b16.number": false,
        "tasks.fieldValues.58c43428-c39a-4c63-991f-d693eae32767.value": false,
        "tasks.fieldValues.702608e4-3dc1-4610-bab8-bf52f3461d59.number": false,
        "tasks.fieldValues.68c306dc-d711-4232-be6b-e9d7770b36ee.value": false,
        "tasks.fieldValues.83bde1b6-086a-4073-b3c6-52f81a6b1caa.number": false,
        "tasks.fieldValues.3284219b-6cbf-43ce-8c50-a2dd63f3b035.choiceId":
          false,
        "tasks.fieldValues.9454d58e-6b60-43d4-9461-a58e3b13bf74.number": false,
        "tasks.fieldValues.b938388a-d281-46c5-ac19-9f949f67abb8.choiceId": true,
        "tasks.fieldValues.eab2c0d1-8a47-4a60-877d-b85a0ef9f252.choiceId":
          false,
        "tasks.fieldValues.3fd9c5d2-fcf8-4bab-9c58-31cb0a8be995.user": true,
        "tasks.fieldValues.e0ae21e1-1535-4d0d-a2da-03423e2de836.number": false,
        "tasks.fieldValues.01dc5b3b-dfb8-4850-957f-8282e69188a3.note": false,
        "tasks.fieldValues.5d90d3c1-a195-4cc9-a17d-09f93cc8c7dc.note": false,
        "tasks.fieldValues.70db637b-4bcf-4d23-a7e3-d5534a5ac2d9.value": false,
        "tasks.fieldValues.068df767-3f63-40d5-8c02-d1b1ee443acc.choiceId":
          false,
        "tasks.fieldValues.175aebcc-f91c-47f7-8568-2b72998c57f8.date": false,
        "tasks.fieldValues.c56c8c3b-99df-443b-9954-f47f916647d0.user": false,
        "tasks.fieldValues.45a9390e-acd0-40da-9e3c-1609ddde5145.person": false,
        "tasks.fieldValues.79a2dd12-40b5-4419-ae4d-0a35c0f225c3.person": false,
        "tasks.fieldValues.175aebcc-f91c-47f7-8568-2b72998c57f8.number": false,
        "tasks.fieldValues.4803b5cb-43da-4829-aaf0-c887347b35bd.value": false,
        "tasks.fieldValues.6a164534-5cd6-462e-8ce6-8ea5e0084c2d.value": false,
        "tasks.fieldValues.fdfbd334-c0ae-4592-8933-f99d82453c7c.choiceId":
          false,
        "tasks.fieldValues.86fef223-2013-4da7-85d4-4fd68b98bde5.choiceId":
          false,
        dateAdded: false,
      },
      order: [
        "tasks.fieldValues.44fcec37-381d-bfae-6c4f-e4f530fb376e.value",
        "tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.company",
        "tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId",
        "tasks.fieldValues.f9b90b15-9b4b-421e-a58e-c5e9e17490aa.number",
        "tasks.fieldValues.33145d84-f8f5-4e2b-9c32-1bd38c1bdc22.choiceId",
        "tasks.fieldValues.59dfaa30-41a6-44ce-b69a-0c01481570a5.choiceId",
        // "tasks.fieldValues.876ee602-842d-4327-9b9e-a88d99bc281f.person",
        "tasks.fieldValues.e2e0c787-44e4-4189-9116-7a5e670ae2f5.user",
        "tasks.fieldValues.0330308f-5c24-41a4-8395-ac1f7c9348b7.value",
        "tasks.fieldValues.bf70b5b6-b3c8-44f8-a1e6-02fcac632b2f.value",
        "tasks.fieldValues.b938388a-d281-46c5-ac19-9f949f67abb8.choiceId",
        "tasks.fieldValues.3fd9c5d2-fcf8-4bab-9c58-31cb0a8be995.user",
      ],
    },
  };

  return (
    <Page>
      <Page.Title>New Opportunity Distribution</Page.Title>
      <Page.Content>
        <Card>
          <Box
            m={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
            }}
            gap={1}
          >
            <ButtonTabs
              width="320px"
              options={[
                { label: "Qualified", key: "QUALIFIED" },
                { label: "Unqualified", key: "UNQUALIFIED" },
              ]}
              activeKey={qualifiedStatus}
              onClick={({ key }) => setQualifiedStatus(key)}
            />
            <DatePicker
              label="Date Added"
              format="YYYY-MM-DD"
              value={dateAddedFilter ? dayjs(dateAddedFilter) : null}
              onChange={(date) => {
                setdateAddedFilter(date.format("YYYY-MM-DD"));
              }}
            />
          </Box>
          {isProcessLoading || isLoading ? <Progress /> : null}
          <TableView
            enableViews={false}
            tasks={tasks.data}
            processId="deals"
            isLoading={isProcessLoading || isLoading}
            fields={[...processData.fields, ...processData.organizationFields]}
            mode="ungrouped"
            sorting={sortStatement}
            columnFilters={[]}
            columnVisibility={columnVisOrder[qualifiedStatus].vis}
            columnOrder={columnVisOrder[qualifiedStatus].order}
            enableColumnFilter={false}
            enableSorting={false}
          />
        </Card>
      </Page.Content>
    </Page>
  );
}

export default NewlyAddedOpportunities;
