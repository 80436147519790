import React, { useState, useEffect } from "react";
import {
  getOverrideOrg,
  overrideOrganization,
  Organization,
  getOrgValues,
} from "@/api/Organization";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import InternalSearch from "@/components/InternalIdLookup";
import Override from "./Override";
import OrgFieldValues from "./OrgFieldValues";

interface OrganizationOverrideProps {
  company: Organization;
}

export function OrganizationOverride({
  company: { valorId },
}: OrganizationOverrideProps) {
  const [currentState, setCurrentState] = useState({});

  const { data: fieldValues = [], refetch: refetchOrgValues } = useQuery(
    ["orgValues", valorId],
    () => getOrgValues(valorId),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOverrideOrg({ valorId });
      setCurrentState(data);
      return data;
    };
    fetchData();
  }, [valorId]);

  return (
    <Box>
      <InternalSearch />
      <Override
        valorId={valorId}
        currentState={currentState}
        onSubmit={overrideOrganization}
      />

      <OrgFieldValues
        valorId={valorId}
        fieldValues={fieldValues}
        onUpdate={() => refetchOrgValues()}
        refetchOrgValues={refetchOrgValues}
      />
    </Box>
  );
}
export default OrganizationOverride;
